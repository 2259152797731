/* A Global */
html {
  overflow-y: scroll;
}

body {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  overflow-x: hidden !important;

}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  text-decoration: none !important;
}


/* move to /app/fonts.ts */
//@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;600;700&display=swap&subset=latin-ext");

/* move to /app/fonts.ts - not used ?? */
//@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap&subset=latin-ext");


/* not used -> for <Icon>star</Icon>*/
//@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import 'colors';

/* Colors */
$white: #ffffff;
$gray: #d1dce8;
$dark-gray: #92a5c7;
$darker-gray: #536382;
$yellow: #fdd22f;
$orange: #fc9527;
$pink: #ec87d8;
$green: #4fbe65;
$blue: #1ba8ce;
$dark-blue: #164194;
$black: #2a364b;
$red: #ff0000;
$light-gray: #ebf5f9;
$light-gary-05-alpha:  rgba(235, 245, 249, 0.5);
$lighter-blue: #f3f8fa;
$light-blue: #f4f8ff;
$light-aqua: #def8f3;
$light-yellow: #fdf7e3;
$light-pink: #fbf1f9;
$light-purple: #f2f1fb;
$light-red: #fdeeee;
$light-orange: #fff5ea;
$light-brown: #f3ebe4;
$light-green: #e7f7e7;

/* Background Colors */
.b-transparent {
  background-color: transparent !important;
}

.b-white {
  background-color: $white !important;
}

.b-blue {
  background-color: $blue !important;
}

.b-gray {
  background-color: $gray !important;
}

.b-orange {
  background-color: $orange !important;
}

.b-yellow {
  background-color: $yellow !important;
}

.b-light-gray {
  background-color: $light-gray !important;
}

.b-lighter-blue {
  background-color: $lighter-blue !important;
}

.b-light-blue {
  background-color: $light-blue !important;
}

.b-light-aqua {
  background-color: $light-aqua !important;
}

.b-light-yellow {
  background-color: $light-yellow !important;
}

.b-light-orange {
  background-color: $light-orange !important;
}

.b-light-pink {
  background-color: $light-pink !important;
}

.b-light-purple {
  background-color: $light-purple !important;
}

.b-light-red {
  background-color: $light-red !important;
}

.b-light-brown {
  background-color: $light-brown !important;
}

.b-light-green {
  background-color: $light-green !important;
}

/* Borders / Dividers Colors */
.fcx-border-white {
  border-color: rgba($white, 0.5) !important;
}

.fcx-borderbg-blue {
  background-color: $blue !important;
}

.fcx-borderbg-black {
  background-color: $black !important;
}

.fcx-border-blue {
  border: 1px solid $gray;
}

.fcx-border-vertical-blue {
  border-right: 1px solid $gray;
  border-left: 1px solid $gray;
}

.fcx-border-bottom-blue {
  border-bottom: 1px solid $gray;
}

.fcx-border-left-blue {
  border-left: 2px solid $blue;
  padding-left: 30px;
  margin-left: 15px;
}

.fcx-divider-fat {
  height: 2px !important;
}

/* Border Radius */
.fcx-round-5 {
  border-radius: 5px;
  overflow: hidden;
}

.fcx-round-10 {
  border-radius: 10px;
}

/* Columns */
.fcx-columns-120 {
  min-height: 120px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

/* Display */
.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

/* DOM Position */
.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

/* DOM Alignment */
.p-float-left {
  float: left !important;
}

.p-float-left-2-right {
  float: left !important;
}

.p-float-right {
  float: right !important;
}

.p-float-right-2-left {
  float: right !important;
}

@media (max-width: 959px) {
  .p-float-left-2-right {
    float: right !important;
  }
  .p-float-right-2-left {
    float: left !important;
  }
}

/* Links and Cursors */
.fcx-cursor-pointer {
  cursor: pointer;
}

/* Fill Colors */
.fill-blue path {
  fill: $blue !important;
}

.fill-orange path {
  fill: $orange !important;
}

/* Font Colors */
.f-transparent {
  color: transparent !important;
}

.f-orange {
  color: $orange !important;
}

.f-dark-blue {
  color: $dark-blue !important;
}

.f-blue {
  color: $blue !important;
}

.f-red {
  color: $red !important;
}

.f-green {
  color: $green !important;
}

.f-white {
  color: $white !important;
}

.f-gray {
  color: $gray !important;
}

.f-dark-gray {
  color: $dark-gray !important;
}

.f-darker-gray {
  color: $darker-gray !important;
}

.f-black {
  color: $black !important;
}

/* Font Weights */
.f-light {
  font-weight: 400 !important;
}

.f-normal {
  font-weight: 500 !important;
}

.f-bold {
  font-weight: 600 !important;
}

/* Images */
.fcx-img-fullwidth {
  width: 100%;
  height: auto;
}

/* Grid */
.grid-25 {
  width: 25%;
}

.grid-50 {
  width: 50%;
}

.grid-75 {
  width: 75%;
}

.grid-100 {
  width: 100%;
}

/* Height */
.fcx-min-height-500 {
  min-height: 500px;
}

/* Margins and Paddings */
.fcx-mb-none {
  margin-bottom: 0 !important;
}

.fcx-mb-5 {
  margin-bottom: 5px !important;
}

.fcx-mb-10 {
  margin-bottom: 10px !important;
}

.fcx-mb-20 {
  margin-bottom: 20px !important;
}

.fcx-mb-30 {
  margin-bottom: 30px !important;
}

.fcx-mb-40 {
  margin-bottom: 40px !important;
}

.fcx-mt-none {
  margin-top: 0 !important;
}

.fcx-mt-5 {
  margin-top: 5px !important;
}

.fcx-mt-10 {
  margin-top: 10px !important;
}

.fcx-mt-20 {
  margin-top: 20px !important;
}

.fcx-mt-30 {
  margin-top: 30px !important;
}

.fcx-mt-40 {
  margin-top: 40px !important;
}

.fcx-mr-none {
  margin-right: 0 !important;
}

.fcx-mr-5 {
  margin-right: 5px !important;
}

.fcx-mr-10 {
  margin-right: 10px !important;
}

.fcx-mr-20 {
  margin-right: 20px !important;
}

.fcx-mr-30 {
  margin-right: 30px !important;
}

.fcx-mr-40 {
  margin-right: 40px !important;
}

.fcx-ml-none {
  margin-left: 0 !important;
}

.fcx-ml-5 {
  margin-left: 5px !important;
}

.fcx-ml-10 {
  margin-left: 10px !important;
}

.fcx-ml-20 {
  margin-left: 20px !important;
}

.fcx-ml-30 {
  margin-left: 30px !important;
}

.fcx-ml-40 {
  margin-left: 40px !important;
}

.fcx-mx-none {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.fcx-mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.fcx-mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.fcx-mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.fcx-my-none {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.fcx-my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.fcx-my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.fcx-my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.fcx-pb-none {
  padding-bottom: 0 !important;
}

.fcx-pt-none {
  padding-top: 0 !important;
}

.fcx-pb-10 {
  padding-top: 10px !important;
}

.fcx-pt-5 {
  padding-top: 5px !important;
}

.fcx-pt-10 {
  padding-top: 10px !important;
}

.fcx-pt-20 {
  padding-top: 20px !important;
}

.fcx-pt-30 {
  padding-top: 30px !important;
}

.fcx-pt-40 {
  padding-top: 40px !important;
}

.fcx-pl-none {
  padding-left: 0 !important;
}

.fcx-pl-5 {
  padding-left: 5px !important;
}

.fcx-pl-10 {
  padding-left: 10px !important;
}

.fcx-pl-15 {
  padding-left: 10px !important;
}

.fcx-pl-20 {
  padding-left: 20px !important;
}

.fcx-pl-30 {
  padding-left: 30px !important;
}

.fcx-pl-40 {
  padding-left: 40px !important;
}

.fcx-px-none {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.fcx-px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.fcx-px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.fcx-px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.fcx-px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.fcx-px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.fcx-px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.fcx-py-none {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.fcx-py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.fcx-py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.fcx-py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.fcx-py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.fcx-py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.fcx-py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

/* Opacity */
.fcx-opacity-7 {
  opacity: 0.7;
}

.fcx-opacity-8 {
  opacity: 0.8;
}

.fcx-opacity-9 {
  opacity: 0.9;
}

/* Overflow */
.fcx-overflow-hidden {
  overflow: hidden;
}

/* SVG Strokes */
.s-blue circle {
  stroke: $blue !important;
}

/* Text Alignment */
.p-align-left {
  text-align: left !important;
}

.p-align-left-2-right {
  text-align: left !important;
}

.p-align-left-2-center {
  text-align: left !important;
}

.p-align-right {
  text-align: right !important;
}

.p-align-right-2-left {
  text-align: right !important;
}

.p-align-right-2-center {
  text-align: right !important;
}

.p-align-center {
  text-align: center !important;
}

.p-align-center-2-left {
  text-align: center !important;
}

.p-align-center-2-right {
  text-align: center !important;
}

/* Text Wrap */
.f-break-word {
  word-wrap: break-word;
}

/* Shifting */
.fcx-shift-up-10 {
  position: relative;
  top: -10px;
  margin-bottom: -10px;
}

.fcx-shift-up-40 {
  position: relative;
  top: -40px;
  margin-bottom: -40px;
}

.fcx-shift-up-50 {
  position: relative;
  top: -50px;
  margin-bottom: -50px;
}

.fcx-shift-up-80 {
  position: relative;
  top: -80px;
  margin-bottom: -80px;
}

@media (max-width: 959px) {
  .p-align-left-2-right {
    text-align: right !important;
  }
  .p-align-left-2-center {
    text-align: center !important;
  }
  .p-align-right-2-left {
    text-align: left !important;
  }
  .p-align-right-2-center {
    text-align: center !important;
  }
  .p-align-center-2-left {
    text-align: left !important;
  }
  .p-align-center-2-right {
    text-align: right !important;
  }
}

/****** CUSTOM COMPONENTS START ******/

/* AC Textfield */
.fcx-textfield-no-borders fieldset,
.fcx-textfield-no-borders:hover fieldset {
  border-color: $white !important;
}

/* Icon Box */
.fcx-icon-box-small-bg {
  padding: 19px 20px 15px 17px;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 15px;
}

.fcx-icon-box-mini-bg {
  padding: 12px 12px 5px 12px;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 15px;
}

.fcx-icon-box-mini-bg svg {
  font-size: 46px;
}

.fcx-icon-box-mini-bg-no-margin span {
  margin: 0 !important;
}

/* Image Box */
.fcx-image-box-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.fcx-image-box-action {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 6%;
}

.fcx-image-box-action .MuiCardActions-spacing {
  padding: 8px 0;
}

.fcx-image-box-action .MuiButton-text {
  padding: 6px 30px;
}

.fcx-image-box-badge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 6%;
}

.MuiChip-root {
  border-radius: 5px !important;
  border-color: #ffffff !important;
}

#fcx-carousel-image-box .fcx-image-box-content .MuiChip-outlined {
  //background-color: #0000003d !important;
}

.fcx-fake-button {
  display: inline-block;
  padding: 10px 10%;
  font-weight: 600;
  color: #334f82;
  background: #ffffff;
  border-radius: 5px;
}

.fcx-fake-button:hover {
  background: #ffffffee;
}

/* Image Box Hovered */
.fcx-image-box-content.fcx-hovered .fcx-image-box-action {
  background: transparent;
  background: linear-gradient(
                  180deg,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(0, 0, 0, 0.48) 100%
  );
}

/* Footer */
.fcx-logo-footer {
  object-fit: contain !important;
  position: relative;
  max-width: 210px;
  width: 50%;
  aspect-ratio: 210 / 73;
}

.fcx-logo-payu-footer {
  object-fit: contain !important;
  position: relative;
  width: 110px;
  aspect-ratio: 96 / 34;
  margin-bottom: 10px;
}

@media (max-width: 959px) {
  .fcx-logo-footer {
    width: 70%;
  }
}

@media (max-width: 760px) {
  .fcx-logo-footer {
    width: 210px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 759px) {
  #fcx-footer .MuiList-root {
    padding-bottom: 0;
  }
  #fcx-footer .MuiListItem-root {
    margin-left: 0.5rem
    //padding: 5px 19px 7px 0;
    //width: auto;
    //display: inline-block;
  }
}

/* Product Page */
#fcx-product-intro h2 > p {
  margin-block-start: 0;
}

#fcx-product-intro p > span {
  color: $darker-gray !important;
}


.fcx-product-type span {
  position: relative;
  top: -6px;
}

#fcx-product-full-desc,
#fcx-blog-content,
.fcx-article-claim-item {
  font-size: 0.95rem;
  font-family: var(--font-josefin), "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.54;
  text-decoration: none;
  letter-spacing: 0.00938em;
}

.fcx-article-claim-item * {
  text-decoration: none;
}

//#fcx-product-full-desc h1,
//#fcx-product-full-desc h2,
//#fcx-product-full-desc h3,
//#fcx-product-full-desc h4,
//#fcx-product-full-desc h5,
//#fcx-product-full-desc h6,
//#fcx-blog-content h1,
//#fcx-blog-content h2,
//#fcx-blog-content h3,
//#fcx-blog-content h4,
//#fcx-blog-content h5,
//#fcx-blog-content h6,
//#fcx-category-tabs-content h1,
//#fcx-category-tabs-content h2,
//#fcx-category-tabs-content h3,
//#fcx-category-tabs-content h4,
//#fcx-category-tabs-content h5,
//#fcx-category-tabs-content h6,
//#fcx-document-content h1,
//#fcx-document-content h2,
//#fcx-document-content h3,
//#fcx-document-content h4,
//#fcx-document-content h5,
//#fcx-document-content h6 {
//  color: $dark-blue;
//}

#fcx-document-content hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid $gray;
}

/* img - NOWE BADANIE */
#fcx-product-box {
  padding: 0 28px 28px 28px !important;
}

.new-test-container {
  position: relative;
}

.new-test-img {
  position: absolute;
  right: -28px;
  width: 60px !important;
}

.below-new-test {
  padding-top: 28px !important;
}

/* Blog Page */
#fcx-blog-content a,
#fcx-document-content a {
  color: #1ba8ce;
  text-decoration: none;
  font-weight: 500;
}

#fcx-blog-content a:hover {
  text-decoration: underline;
}

/* Tabs */
.MuiTab-root {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.fcx-tabs-stacked-box .MuiTabs-root {
  float: left;
}

.fcx-tabs-stacked-box .MuiTabs-indicator {
  display: none;
}

.fcx-tabs-stacked-box .MuiTabs-scroller {
  background: $colorText100;
  border-radius: 2rem;
}

.fcx-tabs-stacked-box .MuiTabScrollButton-root {
  background: $colorText100;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  margin: 0 8px 0 8px;
  width: 48px;
  height: 48px;
}

.fcx-tabs-stacked {
  max-width: 100%;
}

/* Vertical Tabs */
.fcx-vertical-tabs-panel.grid-75 li {
  width: 50%;
}

.fcx-vertical-tabs .MuiTab-textColorPrimary.Mui-selected {
  color: $white !important;
  font-weight: 500 !important;
}

.fcx-vertical-tabs .MuiTab-textColorPrimary {
  color: rgba($white, 0.5) !important;
  font-weight: 500 !important;
}

.fcx-vertical-tabs button span {
  align-items: flex-start !important;
}

.fcx-vertical-tabs button {
  padding: 0 20px 3px 0 !important;
  font-size: 1.2rem !important;
  border-bottom: 1px solid #ffffff4a !important;
}

.fcx-vertical-tabs .MuiTabs-indicator {
  background-color: $white !important;
}

/* Lists */
.fcx-list-with-tick .MuiListItemIcon-root {
  min-width: 33px;
  margin-top: 4px;
}

.fcx-list-with-tick .MuiSvgIcon-root {
  font-size: 1.3rem;
}

.fcx-list-with-radio .MuiListItemIcon-root {
  min-width: 15px;
}

.fcx-list-with-radio .MuiListItem-button {
  padding: 5px 10px !important;
  box-sizing: border-box !important;
  align-items: baseline !important;
}

/* Breadcrumbs */
#fcx-article-intro #fcx-breadcrumbs {
  background: transparent !important;
  padding-top: 0 !important;
}


.fcx-article-claim-item {
  padding: 0 !important;
}

/* Custom List */
.fcx-article-claim-item ul,
#fcx-category-tabs-content ul,
ul.fcx-custom-list {
  list-style: none;
  padding: 0 25px;
  margin: 0;
}

.fcx-article-claim-item a:before {
  content: '>';
  color: $orange;
  margin-right: 6px;
}
.fcx-article-claim-item a, #fcx-blog-tabs-content-elements a {
  color: $colorPrimary700;
  font-family: var(--font-josefin), "Helvetica", "Arial", sans-serif;
}

#fcx-category-tabs-content ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

#fcx-category-tabs-content ul li {
  padding: 10px 20px 10px 0;
}

@media (max-width: 759px) {
  #fcx-category-tabs-content ul {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
}

#fcx-product-full-desc ul {
  padding-left: 25px;
}

#fcx-product-full-desc ul li {
  list-style-type: none;
  margin-bottom: 15px;
}

.fcx-article-claim-item ul li::before,
#fcx-category-tabs-content ul li::before,
ul.fcx-custom-list li::before,
#fcx-product-full-desc ul li:before {
  content: '✔';
  font-size: 1.0rem;
  line-height: 0;
  display: inline-block;
  width: 1.1rem;
  margin-left: -1rem;
  position: relative;
  top: 0;
  left: -5px;
  color: $orange;
}

#fcx-category-tabs-content ul li::before,
ul.fcx-custom-list li::before {
  color: $orange;
  left: -5px;
}

/* Forms */
.fcx-white-form .MuiInputBase-input,
.fcx-white-form .MuiOutlinedInput-multiline,
.fcx-white-form .MuiOutlinedInput-root {
  background: $white;
}

.fcx-form-infotip {
  position: relative;
  top: 4px;
}

.fcx-form-person-company .MuiFormControlLabel-root .MuiTypography-body1 {
  font-size: 1.45rem !important;
  margin-right: 20px;
  color: #164194;
  font-weight: 500;
}

.fcx-form-invoice .MuiTypography-body1 {
  font-weight: 600;
  font-size: 1rem;
}

.fcx-form-payment {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.fcx-form-payment.fcx-active {
  border-color: $orange;
}

.fcx-form-payment .MuiTypography-body1 {
  font-size: 1.2rem;
  font-weight: 500;
  color: $dark-blue;
}

.fcx-form-payment.fcx-active .MuiTypography-body1,
.fcx-form-payment.fcx-active .fcx-payment-label {
  color: $orange;
}

.fcx-form-payment .fcx-logo {
  width: 80px;
  padding: 0 30px 0 20px;
  box-sizing: content-box;
}

/* Quick cart */
.fcx-quic-cart-input {
  & .MuiOutlinedInput-root {
    & .MuiOutlinedInput-notchedOutline {
      border-color: $colorText700;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: $colorText700;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $colorText700;
    }
  }

  & .MuiInputLabel-outlined {
    color: $colorText900;

    &:hover {
      color: $colorText900;
    }

    &.Mui-focused {
      color: $colorText900;
    }
  }
}

.fcx-quick-cart-divider hr {
  border-color: $colorPrimary100;
  margin: 15px 0 15px 0;
}

.fcx-form-quick-payment {
  background: $colorText100;
  border-radius: 2rem;
  padding: 2px 30px 2px 20px !important;
  margin: 0 !important;
}

.fcx-form-quick-payment.fcx-active {
  background: $colorText900;
}

.fcx-form-quick-payment .MuiTypography-body1 {
  font-size: 1rem;
  font-weight: 500;
  color: $colorText900;
}

@media (max-width: 640px) {
  .fcx-form-quick-payment {
    padding: 2px 20px 2px 10px !important;
  }

  .fcx-form-quick-payment .MuiTypography-body1 {
    font-size: 0.9rem!important;
  }
}

.fcx-form-quick-payment.fcx-active {
  z-index: 10;
}

.fcx-form-quick-payment.fcx-active .MuiTypography-body1 {
  color: $colorText50;
}

.fcx-form-label {
  position: relative;
  bottom: -15px;
}

.fcx-quick-payment-step-mini {
  padding-right: 1.2rem;
  margin-right: 1.2rem;
  font-size: 3.6rem;
  font-weight: bold;
  color: $colorPrimary700;
  border-right-style: solid;
  width: 4rem;
}

.fcx-quick-payment-step {
  padding-right: 1.8rem;
  text-align: center;
  font-size: 4.8rem;
  font-weight: bold;
  color: $colorPrimary700;
  border-right-style: solid;
  width: 4rem;
}

@media (max-width: 480px) {
  .fcx-quick-payment-step {
    width: 3rem;
  }
}

.fcx-quick-form-payment-method {
  margin-left: 0 !important;
  margin-right: 0 !important;
  height: 100%;
  -webkit-box-shadow: 0 0 0 1px $colorText600;
  -moz-box-shadow: 0 0 0 1px $colorText600;
  box-shadow: 0 0 0 1px $colorText600;
}

@media (min-width: 760px) {
  .fcx-quick-form-payment-method {
    min-height: 6.35rem;
  }
}

.fcx-quick-payment-label {
  color: $colorPrimary700;
  font-size: 1.1rem !important;
}

.fcx-quick-payment-text {
  color: $colorText600;
  font-size: 0.9rem !important;
}


.fcx-quick-form-payment-method.fcx-active {
  -webkit-box-shadow: 0 0 0 2px $orange;
  -moz-box-shadow: 0 0 0 2px $orange;
  box-shadow: 0 0 0 2px $orange;
  background: rgba(255, 176, 31, 0.05);
}

.fcx-quick-form-payment-method.fcx-active .fcx-quick-payment-label {
  color: $orange;
}

.fcx-quick-form-payment-method .fcx-quick-logo {
  width: 70px;
  padding: 0 20px 0 15px;
  box-sizing: content-box;
}

/* Google Map */
.fcx-google-map {
  width: max-content;
  padding: 20px 40px;
  box-sizing: border-box;
  -webkit-box-shadow: 7px 3px 15px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 7px 3px 15px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 7px 3px 15px 0 rgba(0, 0, 0, 0.24);
  border-left: 4px solid #1ba8ce;
}

.fcx-arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid $blue;
  position: absolute;
  left: -10px;
  top: 30px;
}

/* Contact Page */
#fcx-categories-menu .MuiListItemText-root.f-normal span {
  font-weight: 500 !important;
}

/* Article Box */
@media (max-width: 759px) {
  .fcx-article-box-mobile {
    padding-left: 80px;
  }
}

/* Article Box */
@media (max-width: 959px) {
  #fcx-categories-menu {
    padding: 2% 5%;
  }
  #fcx-categories-menu ul li {
    display: inline-flex;
  }
  #fcx-categories-menu .MuiListItem-root {
    padding-right: 5px !important;
    padding-top: 0 !important;
  }
  #fcx-categories-menu ul li .MuiListItemIcon-root {
    display: none;
  }
  #fcx-categories-menu .MuiListItemText-root {
    background: #fff5ea;
    padding: 5px 10px;
    border-radius: 5px;
  }
}

/* Header */
#fcx-header-nav {
  display: inline-flex !important;
}

#fcx-header .fcx-nav-logo {
  float: left;
}

#fcx-header .fcx-nav-logo .fcx-logo-header {
  width: 200px !important;
  height: 53px;
  display: inline-flex !important;
  position: relative;
  bottom: 7px;
}

#fcx-header .fcx-header-nav-menu {
  display: inline-flex;
}

.fcx-header-nav .tab-main-menu:first-of-type {
  margin-right: 40px;
}
@media (max-width: 1399px) {
  .fcx-header-nav .tab-main-menu:first-of-type {
    margin-right: 20px;
  }
}

#fcx-header .fcx-header-nav-lang {
  position: relative;
  top: 3px;
}

#fcx-header .fcx-header-nav-lang .MuiInputBase-input {
  padding-top: 6px;
  padding-bottom: 6px;
}

#fcx-header .MuiTab-root {
  min-width: 80px;
  color: $black;
  padding: 10px 20px 40px 20px !important;
}

#fcx-header .MuiTab-root.Mui-selected {
  color: $blue;
}

#fcx-header.mini h6 {
  position: relative;
  bottom: 13px;
}

@media (max-width: 1279px) {
  #fcx-header .fcx-nav-logo .fcx-logo-header {
    width: 150px !important;
    height: 40px;
    bottom: unset;
  }
}

@media (max-width: 959px) {
  #fcx-header {
    padding: 10px;
  }
  #fcx-header .fcx-nav-logo .fcx-logo-header {
    width: 120px !important;
    height: 32px;
    bottom: unset;
    left: -5px;
    padding-top: 6px;
  }
  .fcx-header-nav-tools .fcx-header-nav-lang fieldset {
    border: 0;
    margin: -10px 0;
  }
  #fcx-header .MuiButtonBase-root {
    padding: 8px !important;
  }
  #fcx-header.mini .fcx-phone {
    padding: 2px !important;
    min-width: 33px;
    margin-right: 10px !important;
  }
  #fcx-header.mini h6 {
    position: relative;
    bottom: 13px;
  }
}

/* Mega Menu */
.fcx-mega-menu {
  width: 100vw;
  height: auto;
  position: absolute;
  left: 0;
}

.panel-img-wrapper:hover {
  filter: brightness(70%);
  color: $yellow;
}

/* Accordions */
.MuiAccordionSummary-root {
  padding: 0 !important;
}

.MuiAccordionDetails-root {
  padding: 4px 0 8px 4px !important;
}

/* Dialogs */
.MuiDialog-paper {
  padding: 20px 20px 25px 20px !important;
}

#filtration-dialog .MuiDialog-paper {
  padding: 0 !important;
}

.MuiDialogActions-root {
  padding: 8px 25px !important;
  justify-content: space-between !important;
}

/* Mega menu */
.fcx-megamenu {
  position: absolute;
  left: 10px;
  width: calc(100% - 20px) !important;
  height: 450px;
  background: $white;
  box-shadow: 0 10px 25px -20px rgba(113, 141, 152, 0.84);
  border-radius: 8px;
}

.fcx-megamenu::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 220px;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.fcx-megamenu.with-img::before {
  left: 310px;
}

.fcx-megamenu ul {
  width: 100%;
  padding: 20px 0 20px 20px;

}

.fcx-megamenu ul.border-r {
  border-right: 1px solid $colorText500;
}

@media (min-width: 1279px) {
  .fcx-megamenu {
    height: 530px;
  }
  .fcx-megamenu.with-img {
    height: 325px;
    width: max-content !important;
    left: 50%;
    transform: translateX(-50%);
    padding: 25px;
  }
}

.fcx-arrow {
  font-size: 13px !important;
  position: absolute;
  top: 16px;
  right: 10px;
}
.fcx-link-arrow {
  font-size: 13px !important;
  color: $orange;
  margin-right: 8px;
  margin-top: 12px;
}

@media (max-width: 1279px) {
  .fcx-mega-submenu ul li a {
    font-weight: 400;
    font-size: 0.9rem;
    padding: 8px 0;
  }
  .fcx-megamenu ul li a {
    font-size: 0.9rem;
  }
  .fcx-megamenu ul {
    padding-left: 3vw;
  }
}

.fcx-mega-submenu {
  width: 100%;
  padding: 0 15px 0 35px;
}

.fcx-mega-submenu ul li a {
  font-weight: 400;
  padding: 8px 0;
}

.fcx-mega-submenu ul {
  width: 100%;
  padding-top: 15px;
  padding-left: 0;
  padding-right: 8px;
}

.fcx-menu-item {
  cursor: pointer !important;
  text-decoration: none !important;
  display: block;
  width: 100%;
  padding: 10px;
}

.fcx-menu-item:hover {
  color: #164194 !important;
  background: $colorPrimary100;
}

.fcx-megamenu .MuiListItem-root {
  padding: 0;
}

/* Stepper */
#fcx-form-stepper .MuiStepper-root {
  background: transparent;
}

#fcx-form-stepper .MuiStepLabel-root {
  position: relative;
  bottom: 1rem;
}

#fcx-form-stepper .MuiStepLabel-label {
  color: $dark-blue;
  font-weight: 500;
  font-size: 1rem;
}

#fcx-form-stepper .MuiStepLabel-label.MuiStepLabel-active,
#fcx-form-stepper .MuiStepLabel-label.MuiStepLabel-completed {
  color: $orange;
  font-weight: 600;
}

#fcx-form-stepper .MuiStepIcon-root {
  font-size: 3.5rem;
  z-index: 1;
  color: $white;
  border: 1px solid #c4d0e8;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 5px #eef3fe;
  -moz-box-shadow: 0 0 0 5px #eef3fe;
  box-shadow: 0 0 0 5px #eef3fe;
}

#fcx-form-stepper .MuiStepIcon-root.MuiStepIcon-active,
#fcx-form-stepper .MuiStepIcon-root.MuiStepIcon-completed {
  border: 1px solid #fbdfbe;
  -webkit-box-shadow: 0 0 0 5px #fef6ee;
  -moz-box-shadow: 0 0 0 5px #fef6ee;
  box-shadow: 0 0 0 5px #fef6ee;
}

#fcx-form-stepper .MuiStepIcon-root .MuiStepIcon-text {
  font-weight: 600;
  fill: $dark-blue;
}

#fcx-form-stepper .MuiStepIcon-root.MuiStepIcon-active .MuiStepIcon-text,
#fcx-form-stepper .MuiStepIcon-root.MuiStepIcon-completed {
  fill: #fc9526;
}

.MuiStepConnector-line {
  border-color: #ced8ec;
}

/* Checkout */
.fcx-divider:nth-last-child(2) hr {
  background-color: #000000;
  height: 2px;
}

/* Cart */
.fcx-cart-amount {
  position: relative;
  top: 18px;
  margin-right: 10px;
}

.fcx-cart-item-price {
  position: relative;
  top: 7px;
  right: 15px;
}

@media (max-width: 759px) {
  .fcx-cart-item-price {
    top: 10px;
  }
}

/* HeaderMobileNav */
body.menu-open {
  margin: 0; height: 100%; overflow: hidden
}

#fcx-mobile-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $white;
  z-index: 3;
}

#fcx-mobile-nav-top {
  background-color: $colorPrimary700;
  position: relative;
  width: 100%;
  height: 136px;
  padding: 0;
}

#fcx-mobile-nav-bottom {
  position: relative;
  width: 100%;
  height: calc(100vh - 136px);
  overflow-y: scroll;
  padding: 0;
}

.menu-open .fcx-courier, .menu-open .fcx-lab-results {
  background-color: rgb(31, 73, 155);
}

.fcx-btn-fullwidth button {
  width: 100%;
}

.menu {
  width: 100%;
  position: relative;
  top: 25px;
  color: $darker-gray;
}

.menu-item {
  height: auto;
  display: flex;
  align-items: center;
  border-radius: 4px;
  transition: background 0.2s;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: $darker-gray;
}

.menu-item.mobile {
  padding: 1.2vh 20px;
  font-size: 1.1rem;
}

.menu-item.mobile:nth-child(odd) {
  background: $light-gary-05-alpha;
}

.menu-item .icon-button {
  margin-right: 0.5rem;
  position: relative;
  top: 5px;
}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: $light-gray!important;
}

@media (hover: none) {
  .menu-item:hover { background-color: transparent; }
}

.menu-item a:hover {
  text-decoration: none !important;
}

.menu-item a {
  min-width: 100%;
}

.icon-right {
  margin-left: auto;
}

/* HeaderMobileNav Transitions */
.menu-primary-appear {
  position: absolute;
  display: block;
}

.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}

.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all 0.2s ease;
}

.menu-primary-exit {
  position: absolute;
}

.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all 0.2s ease;
}

.menu-secondary-appear {
  position: absolute;
  display: block;
}

.menu-secondary-enter {
  transform: translateX(110%);
}

.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all 0.2s ease;
}

.menu-secondary-exit {
  position: absolute;
}

.menu-secondary-exit-active {
  transform: translateX(-110%);
  transition: all 0.2s ease;
}

.menu-last-appear {
  position: absolute;
  display: block;
}

.menu-last-enter {
  transform: translateX(110%);
}

.menu-last-enter-active {
  transform: translateX(0%);
  transition: all 0.2s ease;
}

.menu-last-exit {
  position: absolute;
}

.menu-last-exit-active {
  transform: translateX(110%);
  transition: all 0.2s ease;
}

/* Star Rating */
.fcx-star-ratings {
  border: 1px solid $white;
  background: rgba(255, 255, 255, 0.31);
  display: inline-block;
  padding: 5px 20px 5px 5px;
  border-radius: 10px;
  font-family: var(--font-josefin), "Helvetica", "Arial", sans-serif;
}

.fcx-star-ratings-product {
  display: inline-block;
  padding: 0;
  border-radius: 10px;
  width: 290px;
  font-family: var(--font-josefin), "Helvetica", "Arial", sans-serif;
}

.fcx-star-ratings-avg {
  display: inline-block;
  font-size: 2rem;
  font-weight: 600;
  padding: 11px 10px 4px 10px;
  background: $white;
  border-radius: 4px 4px;
}

/* Lab Partners */
.fcx-labpartners img {
  max-width: 250px;
  display: block;
}

/* Main Categories Menu */
$main-categories-menu-height: 450px;

#fcx-main-categories-menu {
  overflow: visible;
  height: $main-categories-menu-height;
}

#fcx-main-categories-menu .fcx-title {
  height: 40px;
  padding: 25px 30px 0 30px !important;
  margin: 0 !important;
}

#fcx-main-categories-menu > div {
  position: relative;
  width: 100%;
  height: 410px;
  padding: 30px !important;
  margin: 0 !important;
}

#fcx-main-categories-menu > div > ul {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  margin: 0 !important;
}

#fcx-main-categories-menu > div > ul li {
  color: $white;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.84rem;
  padding: 7px 0;
  border-bottom: 1px solid #5fc2dd;
}

#fcx-main-categories-menu > div > ul li:hover {
  opacity: 0.8;
}

#fcx-main-categories-menu > div > ul li::before {
  content: "❯";
  font-size: 12px;
  line-height: 0;
  display: inline-block;
  width: 30px;
  margin-left: -15px;
  position: relative;
  top: 0;
  left: 16px;
}

#fcx-main-categories-menu > div > div {
  position: absolute;
  top: -40px;
  left: calc(100% - 5px);
  height: $main-categories-menu-height;
  width: calc(300% + 36px + 5px);
  background: $blue;
  padding: 30px;
  border-radius: 0 5px 5px 0;
  border-left: 1px solid #5fc2dd;
}

/* Header Nav ArrowDown Icon */
.tab-main-menu span {
  flex-direction: row !important;
}

.search-result-yellow {
  background-color: $yellow !important;
}

#fcx-article-featured-box {
  height: 450px;
}

@media (max-width: 959px) {
  #fcx-article-featured-box {
    max-height: 180px;
  }
}

/* Google Map */
#fcx-product-full-desc figure,
#fcx-blog-content iframe,
#fcx-category-tabs figure {
  margin: 0 !important;
}

#fcx-product-full-desc figure table,
#fcx-blog-content figure table,
#fcx-category-tabs figure table {
  width: 100%;
}

#fcx-product-full-desc figure.media > iframe,
#fcx-blog-content iframe {
  width: 100%;
  height: 500px;
}

@media (max-width: 1279px) {
  #fcx-product-full-desc figure.media > iframe,
  #fcx-blog-content iframe {
    width: 100%;
    height: 450px;
  }
}

@media (max-width: 959px) {
  #fcx-product-full-desc figure.media > iframe,
  #fcx-blog-content iframe {
    width: 100%;
    height: 400px;
  }
}

@media (max-width: 759px) {
  #fcx-product-full-desc figure.media > iframe,
  #fcx-blog-content iframe {
    width: 100%;
    height: 360px;
  }
}

/* Images in Content */
#fcx-product-full-desc figure.image > img,
#fcx-blog-content figure.image > img {
  width: 100%;
  height: auto;
}

/* Raty */
.fcx-raty-default {
  margin-top: 11px !important;
  color: $dark-gray !important;
  font-size: 0.75rem !important;
}

.fcx-raty-default a {
  color: $dark-gray !important;
  font-weight: 600 !important;
  text-decoration: none !important;
  font-size: 0.75rem !important;
}

#fcx-raty-button {
  border: 1px solid $gray !important;
  color: $dark-blue !important;
  font-weight: 600 !important;
  padding: 8.5px !important;
}

#fcx-raty-button div > span {
  width: 100% !important;
  display: block !important;
  text-align: center !important;
}

#fcx-raty-button a {
  color: $dark-blue !important;
  font-weight: 600 !important;
  text-decoration: none !important;
  padding: 20px 0 !important;
}

#fcx-paypo-button {
  border: 1px solid $gray !important;
  color: $dark-blue !important;
  font-weight: 600 !important;
  padding: 8.5px !important;
}

#fcx-paypo-button div > span {
  width: 100% !important;
  display: block !important;
  text-align: center !important;
}

#fcx-paypo-button a {
  color: $dark-blue !important;
  font-weight: 600 !important;
  text-decoration: none !important;
  padding: 20px 0 !important;
}

.fcx-dialog-paypo {
  padding: 0 !important;
}

.fcx-dialog-paypo-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.fcx-dialog-paypo-x {
  cursor: pointer;
}

.fcx-dialog-paypo-content {
  color: #555 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 200 !important;
  line-height: 23px !important;
  margin-top: 21px !important;
  text-align: left !important;
}

/* STYLE ZE STAREJ STRONY START */
.fcx-blog-feed {
  list-style: none;
  padding-left: 0;
}

.fcx-blog-feed li {
  border-bottom: 1px solid $gray;
  margin-bottom: 10px;
  clear: both;
  min-height: 60px;
}

.fcx-blog-feed img {
  width: 80px;
  margin-right: 15px;
  float: left;
}

.fcx-blog-feed h4 {
  padding-top: 5px;
}

#fcx-blog-content a h3 {
  color: $blue;
}

.img-full-width {
  width: 100%;
}

.fcx-lab-container {
  min-height: 240px !important;
}

.vertical-timeline-content {
  border: 1px solid #d1dde9;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
  text-align: center;
}

.fcx-lab-container h3 {
  margin: 0;
}

#fcx-blog-content .btn {
  padding: 10px 20px;
  border-radius: 4px;
  background-color: $orange;
  color: #fff;
  display: inline-block;
}

#fcx-blog-content .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
}

#fcx-blog-content .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: $lighter-blue;
}

#fcx-blog-content .table > thead > tr > th,
#fcx-blog-content .table > tbody > tr > th,
#fcx-blog-content .table > tfoot > tr > th,
#fcx-blog-content .table > thead > tr > td,
#fcx-blog-content .table > tbody > tr > td,
#fcx-blog-content .table > tfoot > tr > td {
  border-top: 1px solid $gray;
  line-height: 1.42857;
  padding: 8px;
  vertical-align: top;
}

#fcx-blog-content .tick-item {
  margin-top: -5px;
  display: inline-block;
  width: 100%;
}

#fcx-blog-content .tick-icon {
  width: 20px !important;
  height: auto;
  position: absolute;
}

#fcx-blog-content .tick-label {
  padding: 0 0 0 30px;
  margin: 0 0 15px 0;
}

#fcx-blog-content .font-bold {
  font-weight: 600;
}

#fcx-blog-content .row img {
  width: 100%;
  height: auto;
}

.m-b-md {
  margin-bottom: 20px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

#fcx-document-content .container:before,
#fcx-document-content .container:after,
#fcx-document-content .row:before,
#fcx-document-content .row:after {
  display: table;
  content: " ";
}

#fcx-document-content :after,
#fcx-document-content :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
  box-sizing: border-box;
  display: block;
}

#fcx-document-content .row:after {
  clear: both;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left;
}

@media (min-width: 1280px) {
  .col-lg-6 {
    width: 50%;
  }
}

@media (min-width: 760px) {
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
}

@media (max-width: 759px) {
  .hidden-xs {
    display: none !important;
  }
}

/* STYLE ZE STAREJ STRONY END */

/* PayU kalkulator rat */
.payu-theme #payu-installment-calc {
  max-width: 100%;
  border: 0;
}

.rightColumn {
  padding-bottom: 40px;
  min-height: 340px;
  border-left: 1px solid $gray;
  background-color: $white !important;
}

/* FIX - do wywalenia, po wdrozeniu nowego mechanizmu zakladek na glownej */
#fcx-tabs-product-box #scrollable-auto-tab-0 {
  order: 4;
}

#fcx-tabs-product-box #scrollable-auto-tab-3 {
  order: 2;
}

#fcx-tabs-product-box #scrollable-auto-tab-2 {
  order: 1;
}

#fcx-tabs-product-box #scrollable-auto-tab-4 {
  order: 3;
}

#fcx-tabs-product-box #scrollable-auto-tab-6 {
  order: 5;
}

#fcx-tabs-product-box #scrollable-auto-tab-5 {
  order: 6;
}

#fcx-tabs-product-box #scrollable-auto-tab-1 {
  order: 7;
}

#fcx-tabs-product-box #scrollable-auto-tab-7 {
  order: 8;
}

.fcxPagination {
  display: inline-block;
}

.fcxPagination a {
  color: $colorText800;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 50px;
}

.fcxPagination a.active {
  background-color: $colorPrimary500;
  color: $colorText50;
}

.fcxPagination a:hover:not(.active) {
  color: $colorOrange;
}

/* Slideout */
#slideout {
  position: fixed;
  bottom: 200px;
  right: -167px;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  z-index: 9999999;
}

#slideout-img {
  width: 250px;
  height: auto;
  -webkit-box-shadow: -4px 2px 12px -7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: -4px 2px 12px -7px rgba(0, 0, 0, 0.3);
  box-shadow: -4px 2px 12px -7px rgba(0, 0, 0, 0.3);
  border-radius: 7px 0 0 7px;
}

#slideout:hover {
  right: 0;
}

.slick-initialized .slick-slide.slick-active {
  z-index: 1;
}

.fullHeight {
  height: 100%;
}

.rem3 {
  font-size: 3rem !important;
}

.rem25 {
  font-size: 2.5rem !important;
}

.buttonSlider {
  font-size: 1.4rem !important;
}

.buttonSliderMobile {
  font-size: 1.3rem !important;
}

.textPromotion {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  color: $colorText50;
  font-size: 30px !important;
}

.circleSlidePromotion {
  background: $colorOrange;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.textSlidePromotion {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  color: $colorText50;
  font-size: 30px !important;
}

.omnibusPrice {
  color: $darker-gray !important;
  font-size: 0.7rem !important;
}

.omnibusStandardPrice {
  color: $dark-gray !important;
  font-size: 0.7rem !important;
}

.omnibusOldPrice {
  text-decoration: line-through;
  color: $dark-gray !important;
  font-size: 1.2rem !important;
}

.short3Line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.topBanner {
  min-height: 2.5rem;
  width: 100%;
  justify-content: center;
  display: flex;
  padding: 0.2rem 2rem 0.2rem 0;
}

//div#__next {
//  position: relative;
//  overflow-y: auto;
//  overflow-x: hidden;
//  height: 100vh;
//}

.topBanner > div {
  align-items: center;
  display: flex;
}

.fcx-background-text-100 {
  background-color: #F4F7FA;
}

#fcx-subheader {
  margin-top: 136px;
  transition: margin 0.2s ease-in-out;
}

#fcx-subheader.shrink {
  margin-top: 68px;
  transition: none;
}

@media (min-width: 1280px) {
  #fcx-subheader {
    margin-top: 164px;
  }
  #fcx-subheader.shrink {
    margin-top: 87.5px;
  }
}