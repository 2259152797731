$colorText50: #fff;
$colorText100: #F4F7FA;
$colorText200: #EEF2F7;
$colorText300: #DCE1E8;
$colorText400: #CBD3DD;
$colorText500: #B7C2D3;
$colorText600: #A0AEC3;
$colorText700: #727B89;
$colorText800: #2D3034;
$colorText900: #1C1E20;
$colorPrimary100: #E8F4FF;
$colorPrimary200: #B9DBEF;
$colorPrimary500: #1BA8CE;
$colorPrimary700: #164194;
$colorOrange: #FC9527;
$colorGreen: #0FC261;
$colorAlergie: #22D3BD;
$colorAlergieLightBg: #E2F7F3;
$colorZKrwi: #FF5454;
$colorZKrwiLightBg: #FBEBEB;
$colorGenetyczne: #8383EA;
$colorGenetyczneLightBg: #F3F1FF;
$colorPrenatalne: #EC87D8;
$colorPrenatalneLightBg: #FAECF8;
$colorZWymazu: #FC9527;
$colorZWymazuLightBg: #FFF5EA;
$colorMikroflora: #FDD22F;
$colorMikrofloraLightBg: #FDF7E3;
$colorSuplementy: #E2F7E2;
$colorSuplementyLightBg: #E2F7E2;
$colorLogoVisa: #142688;
$colorLogoMaster1: #EB001B;
$colorLogoMaster2: #F79E1B;
$colorLogoMaster3: #FF5F00;
$colorLogoZg1: #0077BE;
$colorLogoZg2: #1961AC;